import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import SelectDropdown from '../components/SelectDropdown/SelectDropdown';
import DynamicInputs from '../components/DynamicInputs/DynamicInputs';
import { apiMediaPost, apiRequest } from "../../API";
import FancyButton from "../components/FancyButton/FancyButton";

function CreateAllocation() {
    const navigate = useNavigate();
    const { authData } = useContext(AuthContext);
    const { qp_inventory_id, qp_schema_id } = useParams();

    const [schemas, setSchemas] = useState(null);
    const [schemaOptions, setSchemaOptions] = useState([]);
    const [selectedSchema, setSelectedSchema] = useState(null);

    const [inventories, setInventories] = useState(null);
    const [inventoryOptions, setInventoryOptions] = useState([]);
    const [selectedInventory, setSelectedInventory] = useState(null);

    const [formData, setFormData] = useState({
        prod_id: authData.user.prodId,
        dept_id: authData.user.deptId,
        blueprint_id: '',
        inventory_id: '',
        allocation_data: []
    });

    useEffect(() => {
        document.title = "Create Allocation";

        if (!authData.user.prodId && !authData.user.deptId) {
            navigate('/dashboard');
        } else {
            if (formData.blueprint_id === '' && formData.inventory_id === '') {
                fetchDepartmentInfo();
            }
        }
    }, []);

    useEffect(() => {
        if (schemas && !selectedSchema && qp_schema_id) {
            const schema = schemas.find(item => item.blueprint_id === qp_schema_id);
            if (schema) {
                setSelectedSchema(schema);
                setFormData(prevState => ({ ...prevState, blueprint_id: qp_schema_id }));
            }
        }
    }, [schemas, qp_schema_id]);

    useEffect(() => {
        if (inventories && !selectedInventory && qp_inventory_id) {
            const inventory = inventories.find(item => item.inventory_id === qp_inventory_id);
            if (inventory) {
                setSelectedInventory(inventory);
                setFormData(prevState => ({ ...prevState, inventory_id: qp_inventory_id }));
            }
        }
    }, [inventories, qp_inventory_id]);

    const fetchDepartmentInfo = async () => {
        const [schemasAPI, schemasAPIerror] = await apiRequest(`https://api.setventory.zoty.us/blueprints/attributes/select?production_id=${formData.prod_id}&department_id=${formData.dept_id}`);

        if (!schemasAPIerror && schemasAPI.status === 1) {
            setSchemas(schemasAPI.data);
            setSchemaOptions(schemasAPI.data.map(item => ({ name: item.blueprint_name, id: item.blueprint_id })));
        }

        const [inventoriesAPI, inventoriesAPIerror] = await apiRequest(`https://api.setventory.zoty.us/inventories/attributes/select?production_id=${formData.prod_id}&department_id=${formData.dept_id}`);

        if (!inventoriesAPIerror && inventoriesAPI.status === 1) {
            setInventories(inventoriesAPI.data);
            setInventoryOptions(inventoriesAPI.data.map(item => ({ name: item.inventory_name, id: item.inventory_id })));
        }
    }

    const selectSchema = (id) => {
        console.log("selecting", id)
        const oldSchema = selectedSchema;
        setSelectedSchema(schemas.find(item => item.blueprint_id === id));
        setFormData({ ...formData, blueprint_id: id });
        oldSchema && removeAttributesFromAllocationData(oldSchema.attributes);
    }

    const selectInventory = (id) => {
        const oldInventory = selectedInventory;
        setSelectedInventory(inventories.find(item => item.inventory_id === id));
        setFormData({ ...formData, inventory_id: id });
        oldInventory && removeAttributesFromAllocationData(oldInventory.attributes);
    }

    const handleFormDataChange = (value, name, id, datatype, e) => {
        const index = formData.allocation_data.findIndex(item => item.id === id);
        let inputData = value;

        if (datatype === 'image') {
            inputData = e.target.files[0];
        }

        const allocationData = {
            name: name,
            data: inputData,
            id: id,
            datatype: datatype
        };

        if (index !== -1) {
            const updatedAllocationData = [...formData.allocation_data];
            updatedAllocationData[index] = allocationData;
            setFormData({
                ...formData,
                allocation_data: updatedAllocationData
            });
        } else {
            setFormData({
                ...formData,
                allocation_data: [...formData.allocation_data, allocationData]
            });
        }
    };

    const removeAttributesFromAllocationData = (attributes) => {
        const updatedAllocationData = formData.allocation_data.filter(item => !attributes.some(attribute => attribute.id === item.id));
        setFormData({
            ...formData,
            allocation_data: updatedAllocationData
        });
    };

    const handleSubmit = async () => {
        console.log("submitted formData:", formData);
        console.log("selected schema", selectedSchema);
        const updatedFormData = { ...formData };

        if (selectedSchema) {
            updatedFormData.blueprint_id = selectedSchema.blueprint_id;
        }
        if (selectedInventory) {
            updatedFormData.inventory_id = selectedInventory.inventory_id;

        }
        console.log("submitted formData:", updatedFormData);
        const [data, error] = await apiMediaPost(`https://api.setventory.zoty.us/allocations/create`, updatedFormData);
        if (!error) {
            console.log(data);
            if (data.status === 1) {
                navigate(`/view-inventory/${formData.inventory_id}`);
            }
        }
    };


    return (
        <>
            <h1>Create Allocation</h1>
            <SelectDropdown options={schemaOptions} onSelectChange={selectSchema} thing="Schema" selectedId={qp_schema_id} />
            <SelectDropdown options={inventoryOptions} onSelectChange={selectInventory} thing="Inventory" selectedId={qp_inventory_id} />
            <hr className="spacer" />
            <form>
                {selectedSchema && selectedSchema.attributes.length > 0 && <h2>{selectedSchema.blueprint_name} Data</h2>}
                <DynamicInputs attributes={selectedSchema && selectedSchema.attributes} onFormDataChange={handleFormDataChange} />
                {selectedInventory && selectedInventory.attributes.length > 0 && <h2>{selectedInventory.inventory_name} Data</h2>}
                <DynamicInputs attributes={selectedInventory && selectedInventory.attributes} onFormDataChange={handleFormDataChange} />
            </form>
            <FancyButton
                mainText={"Allocate Item"}
                mainIcon={<i className="fa-duotone fa-check"></i>}
                callback={handleSubmit}
                isSticky={true}
                otherContent={<i className="fa-solid fa-left"></i>}
                otherCallback={"Navigate:Inventories"}
            />
        </>
    );
}

export default CreateAllocation;