import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageLayout from "./pages/PageLayout";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import SignUp from "./pages/SignUp";
import CreateAllocation from './pages/Allocations/CreateAllocation';
import Schemas from "./pages/Schemas/Schemas";
import CreateSchema from './pages/Schemas/CreateSchema';
import EditSchema from './pages/Schemas/EditSchema';
import Inventories from "./pages/Inventories/Inventories";
import CreateInventory from './pages/Inventories/CreateInventory';
import EditInventory from "./pages/Inventories/EditInventory";
import ViewInventory from "./pages/Inventories/ViewInventory";
import Login from "./pages/Login";
import Account from "./pages/Account";
import NoPage from "./pages/NoPage";
import ScrollToTop from "./ScrollToTop";

import './App.css';

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<PageLayout />}>
					<Route index element={<Home />} />
					<Route path="dashboard" element={<Dashboard />} />
					<Route path="schemas" element={<Schemas />} />
					<Route path="create-allocation" element={<CreateAllocation />} />
					<Route path="create-allocation/:qp_inventory_id/:qp_schema_id" element={<CreateAllocation />} />
					<Route path="create-schema" element={<CreateSchema />} />
					<Route path="create-inventory" element={<CreateInventory />} />
					<Route path="edit-schema/:schema_id" element={<EditSchema />} />
					<Route path="edit-inventory/:inventory_id" element={<EditInventory />} />
					<Route path="view-inventory/:inventory_id" element={<ViewInventory />} />
					<Route path="inventories" element={<Inventories />} />
					<Route path="account" element={<Account />} />
					<Route path="signup" element={<SignUp />} />
					<Route path="login" element={<Login />} />
					<Route path="*" element={<NoPage />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;