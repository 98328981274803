import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import InputPairs from '../components/InputGroups/InputGroups';
import TitleGroup from '../components/TitleGroup/TitleGroup';
import FancyButton from "../components/FancyButton/FancyButton";
import { apiPost, apiRequest } from "../../API";
import Popup from "../components/Popup/Popup";
import MiniButtons from "../components/MiniButtons/MiniButtons";
import LoadingPlaceholder from '../components/LoadingPlaceholder/LoadingPlaceholder';

const EditSchema = () => {
    const navigate = useNavigate();
    const { schema_id } = useParams();

    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    
    const [formData, setFormData] = useState({
        blueprint_id: schema_id,
        blueprint_name: '',
        attributes: []
    });

    const fetchData = useCallback(async () => {
        const [res, err] = await apiRequest(`https://api.setventory.zoty.us/blueprints/attributes/select?blueprint_id=${schema_id}`);

        if (!err) {
            if (res.status === 1) {
                const schemaInfo = res.data[0];
                setFormData({
                    blueprint_id: schemaInfo.blueprint_id,
                    blueprint_name: schemaInfo.blueprint_name,
                    attributes: schemaInfo.attributes // Set attributes directly from the API response
                })
            }
        }
        setLoading(false);
    }, [schema_id]);
    
    useEffect(() => {
        document.title = "Edit Schema";

        if (formData.blueprint_name === '') {
            fetchData().then();
        }
    }, [fetchData, formData.blueprint_name]); // Add response to the dependency array

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleAttributesChange = (data) => {
        setFormData({
            ...formData,
            attributes: data.map(field => ({ 
                id: field.id, 
                name: field.name, 
                datatype: field.datatype 
            }))
        });
    };

    const updateSchema = async (e) => {
        console.log("Submit: ", formData);

        const [res, err] = await apiPost('https://api.setventory.zoty.us/blueprints/update', formData);

        if (!err) {
            if (res.status === 1) {
                console.log(res);
                navigate('/schemas');
            }
        }
    }

    const deleteSchema = async (e) => {
        e.preventDefault();
        console.log("Delete: ", {id: formData.blueprint_id});

        const [res, err] = await apiPost(
            'https://api.setventory.zoty.us/blueprints/delete',
            { id: formData.blueprint_id }
        );

        if (!err) {
            if (res.status === 1) {
                console.log(res);
                navigate('/schemas');
            }
        }
    }

    if (loading) {
        return <LoadingPlaceholder amount={0} inputs={true} />;
    }

    return (
        <>
            <div>
                <TitleGroup title={"Edit " + formData.blueprint_name} icon="fa-regular fa-square-dashed" />
                <form>
                    <input
                        type="number"
                        name="blueprint_id"
                        defaultValue={schema_id}
                        hidden
                        readOnly
                    />
                    <div>
                        <label htmlFor="blueprint_name" hidden>Schema Name</label>
                        <input
                            type="text"
                            name="blueprint_name"
                            value={formData.blueprint_name}
                            onChange={handleChange}
                            placeholder="Schema Name"
                        />
                    </div>

                    <InputPairs attributes={formData.attributes} onFormDataChange={handleAttributesChange} />
                </form>
                <MiniButtons
                    btn2Content={<p>Delete <i className="fa-solid fa-xmark-large"></i></p>}
                    btn2Callback={() => setShowPopup(true)}
                />
                <div className="hr-group">
                    <hr hidden />
                </div>
            </div>
            <FancyButton
                mainText={"Confirm Edits"}
                mainIcon={<i className="fa-duotone fa-check"></i>}
                callback={updateSchema}
                isSticky={true}
                otherContent={<i className="fa-solid fa-left"></i>}
                otherCallback={"Navigate:Schemas"}
            />
            {showPopup && (
                <Popup
                    header="Careful!"
                    message="Do you really want to delete this Schema and all of the data in it?"
                    onYes={deleteSchema}
                    onClose={() => setShowPopup(false)}
                />
            )}
        </>
    );
};

export default EditSchema;
