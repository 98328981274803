import React, { useState } from 'react';

const DynamicInputs = ({ attributes = [], onFormDataChange }) => {
    // State to store the form data
    const [formData, setFormData] = useState({});

    // Function to handle input changes
    const handleInputChange = (e, name, id, datatype) => {
        const { value, files } = e.target;
        let updatedValue = value;

        if (datatype === 'image') {
            updatedValue = files[0]; // For image input, use files array
        }
        
        // Update local formData state
        const updatedFormData = {
            ...formData,
            [name]: updatedValue
        };
        setFormData(updatedFormData);

        // Call parent onFormDataChange function with updated form data
        if (typeof onFormDataChange === 'function') {
            onFormDataChange(updatedValue, name, id, datatype, e);
        }
    };

    return (
        <div>
        {attributes && attributes.map(attribute => {
            const { id, name, datatype } = attribute;
            return (
            <div key={id}>
                <label htmlFor={name}>{name}</label>
                {datatype === 'text' && (
                    <input
                        type="text"
                        id={id}
                        name={name}
                        value={formData[name] || ''}
                        onChange={e => handleInputChange(e, name, id, datatype)}
                        placeholder="Text"
                    />
                )}
                {datatype === 'image' && (
                    <input
                        type="file"
                        id={id}
                        name={name}
                        accept="image/*"
                        onChange={e => handleInputChange(e, name, id, datatype)}
                    />
                )}
                {datatype === 'date' && (
                    <input
                        type="date"
                        id={id}
                        name={name}
                        value={formData[name] || ''}
                        onChange={e => handleInputChange(e, name, id, datatype)}
                    />
                )}
            </div>
            );
        })}
        </div>
    );
};

export default DynamicInputs;
