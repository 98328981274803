import React from 'react';
import './Popup.css';

const Popup = ({ header, message, onYes, onClose }) => {
	return (
		<div className="popup-overlay">
			<div className="popup">
				<div className="popup-content">
					{header && <h2>{header}</h2>}
					{message && <p>{message}</p>}
					<div className="button-container">
						<button onClick={onClose}>No</button>
						<button onClick={onYes}>Yes</button>
					</div>
				</div>
			</div>
		</div>
		);
}

export default Popup;
