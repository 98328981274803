import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { AuthContext } from '../AuthContext';
import { apiRequest } from "../API";

function Login() {
    const navigate = useNavigate();
    const { authData, updateAuthData } = useContext(AuthContext);
    
    const [error, setError] = useState([]);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
        document.title = "Log In";

        if (authData.isLoggedIn) {
            navigate('/dashboard');
        }
    });

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const { email, password } = formData;
        let md5pass = generateMD5(password);

        const [res, err] = await apiRequest(`https://api.setventory.zoty.us/users/select?email=${email}&password=${md5pass}`);

        if (!err) {
            if (res.status === 1) {
                if (res.data && res.data.length === 0) {
                    setError("Credentials are incorrect");
                } else {
                    const userData = res.data[0];

                    updateAuthData({
                        isLoggedIn: true,
                        user: {
                            id: userData.user_id,
                            name: userData.user_realname,
                            email: userData.user_email,
                            prodId: userData.user_prod_ids,
                            deptId: userData.user_dept_ids
                        }
                    });

                    navigate('/dashboard');
                }
            }
        }
    };

    function generateMD5(password) {
        return CryptoJS.MD5(password).toString();
    }

    return (
        <div>
            <h1>Log In</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="email" hidden>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        minLength="3"
                        placeholder="Email address"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="password" hidden>Password:</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        minLength="3"
                        placeholder="Password"
                        required
                    />
                </div>
                <button className="primary" type="submit">Log In</button>
                {error && <p className="error-text">{error}</p>}
            </form>
            <div className="hr-group">
                <hr />
                <p><span>or</span></p>
            </div>
            <Link to="/signup"><button className="muted">Sign Up</button></Link>
        </div>
      );
}

export default Login;