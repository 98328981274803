import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import InputPairs from '../components/InputGroups/InputGroups';
import TitleGroup from '../components/TitleGroup/TitleGroup';
import { apiPost } from '../../API';
import FancyButton from "../components/FancyButton/FancyButton";

function Schemas() {
    const navigate = useNavigate();
    const { authData } = useContext(AuthContext);

    const [missingItems, setMissingItems] = useState([]);
    const [formData, setFormData] = useState({
        prod_id: authData.user.prodId,
        dept_id: authData.user.deptId,
        blueprint_name: '',
        attributes: []
    });

    useEffect(() => {
        document.title = "Create Schema";
    }, []);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleAttributesChange = (data) => {
        setFormData({
            ...formData,
            attributes: data.map(field => ({ 
                id: field.id, 
                name: field.name, 
                datatype: field.datatype 
            }))
        });
    };

    const handleSubmit = async () => {
        const [res, err] = await apiPost('https://api.setventory.zoty.us/blueprints/create', formData);

        if (!err) {
            if (res.status === 1) {
                navigate('/schemas');
            } else {
                setMissingItems(res.missing);
            }
        }
    }

    return (
        <>
            <TitleGroup title="Create Schema" icon="fa-regular fa-square-dashed" />
            <p>This is like creating a blueprint for an item in your inventory. 
                You set Attributes which are fields attached to an item. For example, serial number or the person borrowing it.</p>
            <br />
            <form>
                <div>
                    <label htmlFor="blueprint_name" hidden>Schema Name</label>
                    <input
                        type="text"
                        name="blueprint_name"
                        value={formData.blueprint_name}
                        onChange={handleChange}
                        style={{ borderColor: missingItems.includes('blueprint_name') ? 'var(--red)' : '' }}
                        placeholder="Schema Name"
                    />
                </div>
                <input
                    type="hidden"
                    name="prod_id"
                    value={Number(formData.prod_id)}
                    onChange={handleChange}
                />
                <input
                    type="hidden"
                    name="dept_id"
                    value={Number(formData.dept_id)}
                    onChange={handleChange}
                />
                <InputPairs onFormDataChange={handleAttributesChange} />
            </form>
            <div className="hr-group">
                <hr hidden />
            </div>
            <FancyButton
                mainText={"Create Schema"}
                mainIcon={<i className="fa-solid fa-plus"></i>}
                callback={handleSubmit}
                isSticky={true}
                otherContent={<i className="fa-solid fa-left"></i>}
                otherCallback={"Navigate:Schemas"}
            />
        </>
    );
}

export default Schemas;