import React, { useState } from 'react';
import './InputGroups.css';

function InputPairs({ attributes=[], onFormDataChange }) {
    const [fields, setFields] = useState(attributes); // Initialize fields with attributes

    const handleChange = (index, key, value) => {
        const newFields = [...fields];
        newFields[index][key] = value;
        setFields(newFields);
        onFormDataChange(newFields);
    };

    const handleAddField = () => {
        setFields([...fields, { id: 0, name: '', datatype: '' }]);
    };

    const deleteField = (index, id) => {
        const newFields = [...fields];

        newFields.splice(index, 1);
        setFields(newFields);
        onFormDataChange(newFields);
    };

    return (
        <div>
            {fields.map((field, index) => (
                <div className="input-group" key={index}>
                    <input
                        type="number"
                        value={field.id}
                        onChange={(e) => handleChange(index, 'id', e.target.value)}
                        hidden
                    />
                    <input
                        type="text"
                        value={field.name}
                        onChange={(e) => handleChange(index, 'name', e.target.value)}
                        placeholder={`Attribute ${index}`}
                        required
                    />
                    <select
                        value={field.datatype}
                        onChange={(e) => handleChange(index, 'datatype', e.target.value)}
                        required
                    >
                        <option value="" disabled>Select Type</option>
                        <option value="text">Text</option>
                        <option value="date">Date</option>
                        <option value="image">Image</option>
                    </select>
                    <button type="button" onClick={() => deleteField(index, field.id)} tabIndex={-1}><i className="fa-solid fa-xmark-large"></i></button>
                </div>
            ))}
            <button type="button" onClick={handleAddField}>Add Field</button>
        </div>
    );
}

export default InputPairs;
