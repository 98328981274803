import React from 'react';
import './FancyButton.css';
import { useNavigate } from "react-router-dom";

const FancyButton = ({
		mainText = "Click Me",							// primary button text
		mainIcon = <i className="fa-solid fa-right"></i>,	// primary button icon
		callback,												// primary button callback
		otherContent = null,								// if needed, secondary button text or icon
		otherCallback,											// if needed, secondary button callback
		isSticky = false,								// makes button bar sticky to bottom
}) => {
	const navigate = useNavigate();
	let overallClassname = "fancy-button-area";
	
	if (isSticky)
		overallClassname += " sticky";
	
	if (otherContent)
		overallClassname += " grid";
	
	const handleClick = () => {
		if (typeof callback === 'function') {
			callback();
		} else {
			const parts = callback.toLowerCase().split(":");
			if (parts[0] === "navigate") {
				navigate("/" + parts[1]);
			}
		}
	}
	
	const handleOtherClick = () => {
		if (typeof otherCallback === 'function') {
			otherCallback();
		} else {
			const parts = otherCallback.toLowerCase().split(":");
			if (parts[0] === "navigate") {
				navigate("/" + parts[1]);
			}
		}
	}
	
	const renderOtherButton = () => {
		if (otherContent !== null) {
			return (
				<div className="btn" onClick={handleOtherClick}>
					<p>{ otherContent }</p>
				</div>
			);
		}
	}
	
	return (
		<div className={overallClassname}>
			<div className="fancy-button-container">
				{renderOtherButton()}
				<div className="fancy-button" onClick={handleClick}>
					<p>{ mainText }</p>

					<div className="right-side">
						<p>{ mainIcon }</p>
					</div>
				</div>
			</div>
		</div>
	)	
}

export default FancyButton;