import React from 'react';
import './LoadingPlaceholder.css';

const LoadingPlaceholder = ({
    type = "card",
    amount = 1,
    rows = 2,
    button = true,
    text = false,
    inputs = false
}) => {
    const renderPlaceholders = () => {
        return (
            <>
                {[...Array(amount)].map((e, i) => (
                    <div className="card" key={i}>
                        <div>
                            <p></p>
                        </div>
                        <div>
                            <p></p>
                            {rows === 3 && <p></p>}
                        </div>
                    </div>
                ))}
            </>
        );
    }

    const renderInputs = () => {
        return (
            <>
                <div className="text-input-placeholder"></div>
                <div className="desc-input-placeholder"></div>
            </>
        )
    }

    return (
        <>
            <div className="loading-placeholders">
                <h1></h1>
                {text && <p></p>}
                {renderPlaceholders()}
                {inputs && renderInputs()}
                {button && <div className="card primary button">
                    <div>
                        <p></p>
                    </div>
                </div>}
            </div>
        </>
    )
}

export default LoadingPlaceholder;