import React, { useEffect } from 'react';
import TitleGroup from './components/TitleGroup/TitleGroup';

function Home() {

    useEffect(() => {
        document.title = "Homepage";
    });

    return (
        <>
            <h1>Welcome to Setventory</h1>
            <p>Organize your Inventory on a Production.</p>
            <hr className="spacer" />
            <TitleGroup title="Create Schemas" icon="fa-regular fa-square-dashed" heading={2} />
            <p>Associate Attributes with items you want to store in your Inventory. Each Schema is reusable across many Inventory tables and is a structure for a specific item.</p>
            <hr className="spacer" />
            <TitleGroup title="Create Inventories" icon="fa-regular fa-table-list" heading={2} />
            <p>Associate Attributes with items you want to store in your Inventory. Each Schema is reusable across many Inventory tables and is a structure for a specific item.</p>
        </>
    );
}

export default Home;