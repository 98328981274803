import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../AuthContext';
import Card from '../components/Card/Card';
import { apiRequest } from "../../API";
import LoadingPlaceholder from '../components/LoadingPlaceholder/LoadingPlaceholder';

function Schemas() {
    const { authData } = useContext(AuthContext);

    const [schemas, setSchemas] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Schemas";

        if (schemas == null) {
            fetchData();
        }
    });

    const fetchData = async () => {
        const deptId = authData.user.deptId;

        if (deptId !== null) {
            const [res, err] = await apiRequest(`https://api.setventory.zoty.us/blueprints/attributes/select?department_id=${deptId}`);
            if (!err) {
                if (res.status === 1) {
                    console.log(res);
                    setSchemas(res.data);
                }
            }
        }

        setLoading(false);
    }

    if (loading) {
        return <LoadingPlaceholder />
    }
    
    return (
        <>
            <h1>Schemas</h1>
            {schemas && schemas.map((schema, key) => {
                return <Card title={schema.blueprint_name} href={'edit-schema/' + schema.blueprint_id} attributes={schema.attributes} icon="fa-regular fa-square-dashed" key={key} />
            })}
            {schemas && <Card title='Create Schema' href='create-schema' attributes='' icon="fa-regular fa-plus" />}
            {!schemas && <p>You are not in a Production so you can't create these yet.</p>}
        </>
    );
}

export default Schemas;