import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";

function Layout() {
    const pageStyle = {
        margin: '10px'
    };

    return (
        <div>
            <Navbar />
            <div style={pageStyle}>
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;