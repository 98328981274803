import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext.jsx';
import NavbarLink from "./NavbarLink.jsx";
import { leftNavbarLinks, rightNavbarLinks } from './NavbarLinks.js';
import "./Navbar.css";

function Navbar() {
    const navigate = useNavigate();
    const location = useLocation();
    const { authData } = useContext(AuthContext);
    const allowedPages = ['/', '/signup', '/login'];
    const mainPages = ['/', '/signup', '/login', '/dashboard', '/schemas', '/inventories', '/view-inventory', '/account'];
    const currentPath = location.pathname;

    useEffect(() => {
        if (!allowedPages.includes(currentPath)) {
            if (!authData.isLoggedIn) {
                navigate('/login')
            }
        }
    });

    return (
        <>
            <nav className="top">
                {rightNavbarLinks.map(({title, source, requiresAuth, notAuth}, id) => {
                    return (!authData.isLoggedIn) ? 
                        <NavbarLink title={title} href={source} key={id} number={id} activeClassName="active" />
                        : "";
                })}
            </nav>
            <nav className="bottom">
                {leftNavbarLinks.map(({title, source, requiresAuth, notAuth}, id) => {
                    return (authData.isLoggedIn) && mainPages.includes(currentPath) ?
                        <NavbarLink title={title} href={source} key={id} number={id} activeClassName="active" />
                        : "";
                })}
            </nav>
        </>
    );
}

export default Navbar;