import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import InputPairs from '../components/InputGroups/InputGroups';
import TitleGroup from '../components/TitleGroup/TitleGroup';
import { apiPost, apiRequest } from '../../API';
import FancyButton from "../components/FancyButton/FancyButton";
import Popup from "../components/Popup/Popup";
import MiniButtons from "../components/MiniButtons/MiniButtons";
import LoadingPlaceholder from '../components/LoadingPlaceholder/LoadingPlaceholder';

const EditInventory = () => {
    const navigate = useNavigate();
    const { inventory_id } = useParams();

    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(true);

    const [response, setResponse] = useState(null);
    const [missingItems, setMissingItems] = useState([]);
    const [formData, setFormData] = useState({
        inventory_id: inventory_id,
        inventory_name: '',
        inventory_desc: '',
        attributes: []    
    });
    
    useEffect(() => {
        document.title = "Edit Inventory";

        if (response == null) {
            fetchData();
        }
    }, [response]);

    const fetchData = async () => {
        if (inventory_id !== null) {
            const [res, error] = await apiRequest(`https://api.setventory.zoty.us/inventories/attributes/select?inventory_id=${inventory_id}`);

            if (!error) {
                console.log(res);
                if (res.status === 1) {
                    const inventoryInfo = res.data[0];
                    setFormData({
                        inventory_id: inventoryInfo.inventory_id,
                        inventory_name: inventoryInfo.inventory_name,
                        inventory_desc: inventoryInfo.inventory_desc,
                        attributes: inventoryInfo.attributes
                    })
                    setResponse(res);
                }
            }
        }
        setLoading(false);
        
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleAttributesChange = (data) => {
        setFormData({
            ...formData,
            attributes: data.map(field => ({
                id: field.id,
                name: field.name,
                datatype: field.datatype
            }))
        });
    };

    const updateInventory = async () => {
        console.log("sumbit", formData);
        const [res, err] = await apiPost(
            'https://api.setventory.zoty.us/inventories/update',
            formData
        );
        
        if (!err) {
            if (res.status === 1) {
                console.log(res);
                navigate('/view-inventory/' + inventory_id);        
            }
            if (res.missing) {
                setMissingItems(res.missing);
            }
        }
        
    }

    const deleteInventory = async (e) => {
        e.preventDefault();
        console.log("Delete: ", {id: formData.inventory_id});
        const [res, err] = await apiPost(
            'https://api.setventory.zoty.us/inventories/delete',
            { id: formData.inventory_id }
        );
        
        if (!err) {
            if (res.status === 1) {
                console.log(res);
                navigate('/inventories');
            }
        }
    }

    if (loading) {
        return <LoadingPlaceholder amount={0} inputs={true} />;
    }

    return (
        <div>
            <TitleGroup title={"Edit " + formData.inventory_name} icon="fa-regular fa-table-list" />
            <form>
                <input
                    type="number"
                    name="inventory_id"
                    defaultValue={inventory_id}
                    hidden
                    readOnly
                />
                <div>
                    <label htmlFor="inventory_name" hidden>Inventory Name</label>
                    <input
                        type="text"
                        name="inventory_name"
                        value={formData.inventory_name}
                        onChange={handleChange}
                        style={{ borderColor: missingItems.includes('inventory_name') ? 'var(--red)' : '' }}
                        placeholder="Inventory Name"
                    />
                </div>
                <div>
                    <label htmlFor="inventory_desc" hidden>Inventory Desc</label>
                    <textarea
                        type="text"
                        name="inventory_desc"
                        value={formData.inventory_desc}
                        onChange={handleChange}
                        style={{ borderColor: missingItems.includes('inventory_desc') ? 'var(--red)' : '' }}
                        placeholder="Inventory Description"
                        rows="2"
                        autoComplete="off"
                    />
                </div>

                <InputPairs attributes={formData.attributes} onFormDataChange={handleAttributesChange} />
            </form>
            <MiniButtons
                btn2Content={<p>Delete <i className="fa-solid fa-xmark-large"></i></p>}
                btn2Callback={() => setShowPopup(true)}
            />
            <div className="hr-group">
                <hr hidden />
            </div>
            <FancyButton
                mainText={"Confirm Edits"}
                mainIcon={<i className="fa-duotone fa-check"></i>}
                callback={updateInventory}
                isSticky={true}
                otherContent={<i className="fa-solid fa-left"></i>}
                otherCallback={"Navigate:View-Inventory/" + inventory_id}
            />
            {showPopup && (
                <Popup
                    header="Careful!"
                    message="Do you really want to delete this Inventory and all of the data in it?"
                    onYes={deleteInventory}
                    onClose={() => setShowPopup(false)}
                />
            )}
        </div>
    );
};

export default EditInventory;