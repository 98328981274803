import React from 'react';
import './InventoryTables.css';
import { Link } from 'react-router-dom';

const InventoryTables = ({ data }) => {
    // Group data by blueprint_id
    const groupedData = {};
    for (const key in data) {
        const blueprintId = data[key].blueprint_id;
        if (!groupedData[blueprintId]) {
            groupedData[blueprintId] = [];
        }
        groupedData[blueprintId].push(data[key]);
    }

    // Generate table headers dynamically sorted by blueprint attributes first, then inventory attributes
    const generateHeaders = (attributes) => {
        const blueprintAttributes = new Set();
        const inventoryAttributes = new Set();
        attributes.forEach(allocation => {
            allocation.attributes.forEach(attr => {
                if (attr.attribute_type === 'blueprint') {
                    blueprintAttributes.add(attr.attribute_name);
                } else {
                    inventoryAttributes.add(attr.attribute_name);
                }
            });
        });
        return [...blueprintAttributes, ...inventoryAttributes];
    };

    // Generate table rows dynamically
    const generateRows = (allocations, headers) => {
        return allocations.map(allocation => {
            return (
                <tr id={"allocation-" + allocation.allocation_id} key={allocation.allocation_id}>
                    {headers.map((header, index) => {
                        const rowDataItem = allocation.attributes.find(item => item.attribute_name === header);
                        return (
                            <td key={index}>
                                {rowDataItem ? generateAttribute(rowDataItem) : null}
                            </td>
                        );
                    })}
                    <td><Link to={"/edit-allocation/" + allocation.allocation_id}><i className="fa-regular fa-pen-to-square"></i></Link></td>
                </tr>
            );
        });
    };

    const generateAttribute = (attribute) => {
        const { attribute_datatype, allocation_data } = attribute;
        if (attribute_datatype === 'image') {
            const imgUrl = allocation_data.split("../")[1];
            return <img src={"https://api.setventory.zoty.us/" + imgUrl} alt="" width="50" />;
        } else if (isPhoneNumber(allocation_data)) {
            return <a href={"tel:" + allocation_data}>{allocation_data}</a>;
        } else {
            return allocation_data;
        }
    };

    function isPhoneNumber(text) {
        const phoneNumberPattern = /^(\d{3}-?\d{3}-?\d{4}|\d{10})$/;
        return phoneNumberPattern.test(text);
    }

    return (
        <div className="inventory-tables">
            {Object.keys(groupedData).map(blueprintId => (
                <div key={blueprintId}>
                    <h2>{groupedData[blueprintId][0].blueprint_name}</h2>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    {generateHeaders(groupedData[blueprintId]).map((header, index) => (
                                        <th key={index}>{header}</th>
                                    ))}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {generateRows(groupedData[blueprintId], generateHeaders(groupedData[blueprintId]))}
                            </tbody>
                        </table>
                    </div>
                </div>
                ))}
        </div>
        );
};

export default InventoryTables;
