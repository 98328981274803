import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { apiPost } from "../API";

function SignUp() {
    const navigate = useNavigate();
    const { authData, updateAuthData } = useContext(AuthContext);

    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        realname: '',
        email: '',
        password: ''
    });

    useEffect(() => {
        document.title = "Sign Up";
        
        if (authData.isLoggedIn) {
            navigate('/dashboard');
        }
    });

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const sendSignupForm = async (e) => {
        e.preventDefault();

        const [res, err] = await apiPost('https://api.setventory.zoty.us/users/create', formData);

        console.log(res);

        if (!err) {
            if (res.status === 1) {
                const userData = res.data;

                updateAuthData({
                    isLoggedIn: true,
                    user: {
                        id: userData.user_id,
                        name: userData.user_realname,
                        email: userData.user_email,
                        prodId: userData.user_prod_ids,
                        deptId: userData.user_dept_ids
                    }
                });

                navigate('/dashboard');
            } else {
                if (res.error) {
                    setError(res.error);
                }
            }
        }
    }
    
    useEffect(() => {
        document.title = "Sign Up";
    }, []);

    return (
        <>
            <h1>Sign Up</h1>
            <form onSubmit={sendSignupForm}>
                <div>
                    <label htmlFor="realname" hidden>Real name</label>
                    <input
                        type="text"
                        name="realname"
                        value={formData.realname}
                        onChange={handleChange}
                        minLength="3"
                        placeholder="Name"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="email" hidden>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        minLength="3"
                        placeholder="Email address"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="password" hidden>Password</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        minLength="3"
                        placeholder="Password"
                        required
                    />
                </div>
                <button className="primary" type="submit">Sign Up</button>
                {error && <p className="error-text">{error}</p>}
                <div className="hr-group">
                    <hr />
                    <p><span>or</span></p>
                </div>
                <Link to="/login"><button className="muted">Log In</button></Link>
            </form>
        </>
    );
}

export default SignUp;