import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from "../../AuthContext";
import { apiRequest } from "../../API";
import TitleGroup from "../components/TitleGroup/TitleGroup";
import FancyButton from "../components/FancyButton/FancyButton";
import InventoryTables from "../components/InventoryTables/InventoryTables";
import MiniButtons from "../components/MiniButtons/MiniButtons";
import LoadingPlaceholder from '../components/LoadingPlaceholder/LoadingPlaceholder';

const ViewInventory = () => {
	const navigate = useNavigate();
	const { authData } = useContext(AuthContext);
	const { inventory_id } = useParams();
	
	const [inventory, setInventory] = useState(null);
	const [allocations, setAllocations] = useState(null);
	
	useEffect(() => {
		document.title = "View Inventory";

		if (inventory === null) {
			getInventory().then();
		} else {
			if (allocations === null) {
				getAllocations().then();
			}
		}
	}, [inventory, allocations])
	
	const getInventory = async () => {
		if (inventory_id != null) {
			const [res, err] = await apiRequest(`https://api.setventory.zoty.us/inventories/select?id=${inventory_id}`);
			
			if (!err) {
				console.log(res);
				if (res.data[0] && res.data[0].inventory_dept_id !== authData.user.deptId) {
					navigate('/inventories');
				}
				setInventory(res.data[0]);
			}
		}
	}
	
	const getAllocations = async () => {
		const [res, err] = await apiRequest(`https://api.setventory.zoty.us/allocation_data/select?inventory_id=${inventory_id}`);

		if (!err) {
			console.log(res.data);
			const allocationData = (res.data) ? res.data : [];
			setAllocations(allocationData);
		}
	}
	
	const renderInventoryInfo = () => {
		const inventoryName = (inventory && inventory.inventory_name) ? inventory.inventory_name : null;

		if (inventoryName === null) {
			return <LoadingPlaceholder button={false} amount={0} text={true} />;
		}

		return (
			<>
				<TitleGroup title={inventoryName} icon="fa-regular fa-table-list" />
				<p>{inventory.inventory_desc}</p>
			</>
		)
	}

	const getFirstAllocation = () => {
		const allocationId = allocations ? Object.keys(allocations)[0] : null;
		return allocationId && allocations[allocationId].blueprint_id;
	}

	return (
		<>
			<p className="small">INVENTORY</p>
			{renderInventoryInfo()}
			<hr className="spacer" />
			<MiniButtons
				btn1Content={<p>Edit <i className="fa-regular fa-pen-to-square"></i></p>}
				btn1Callback={"Navigate:Edit-Inventory/" + inventory_id}
			/>
			<hr className="spacer" />
			<InventoryTables data={allocations} />
			<FancyButton
				mainText={"Create Allocation"}
				mainIcon={<i className="fa-solid fa-arrow-down-to-bracket"></i>}
				callback={"Navigate:Create-Allocation/" + inventory_id + "/" + getFirstAllocation()}
				isSticky={true}
				otherContent={<i className="fa-solid fa-left"></i>}
				otherCallback={"Navigate:Inventories"}
			/>
		</>
	)
}

export default ViewInventory;