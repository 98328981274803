import React, { useEffect } from 'react';

function NoPage() {
    useEffect(() => {
        document.title = "404";
    }, []);
    
    return (
        <>
            <h1>Not Found</h1>
        </>
    );
}

export default NoPage;