import React from "react";
import { Link } from "react-router-dom";
import "./Card.css";

function Card({ 
            title, // text of the link
             href, // url of the link
      description,
       attributes, // the text attributes for the card
             icon, // alt text for the image
    }) {
    /*
        Inputs
            title : link name
            href : link target
            className : styles class name
            imgSrc : image url
            imgAlt : alt text for image
    */

    function formatAttributes(attributes) {
        if (!Array.isArray(attributes)) {
            return attributes;
        }
        
        let attributeNames = [];

        attributes.forEach(attribute => {
            attributeNames.push(attribute.name)
        });
        
        return attributeNames.join(', ');
        
    }
    
    function getClassName() {
        var activeClass = 'card';
        if (!icon.includes('plus')) {
            activeClass += ' primary';
        }
        return activeClass;
    }
    
    return (
        <div className={getClassName()}>
            <Link to={"/" + href}>
                <div>
                    <p>{title}</p>
                    <i className={icon}></i>
                </div>
                <div>
                    <p>{description && description}</p>
                    <p>{formatAttributes(attributes)}</p>
                </div>
            </Link>
        </div>
    )
}

export default Card;