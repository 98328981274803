import React from "react";
import { NavLink } from "react-router-dom";
// can also use { Link } 

function NavbarLink({
        title, // navbarlink text 
        href // url of the target
    }) {

    return (
        <NavLink to={"/" + href}><p>{title}</p></NavLink>
    );
}

export default NavbarLink;