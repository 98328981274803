import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';

function Dashboard() {
    const [loading, setLoading] = useState(true);
    const { authData, updateAuthData } = useContext(AuthContext);

    useEffect(() => {
        document.title = "Dashboard";

        if (authData.production && authData.department) {
            setLoading(false);
        } else {
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        try {
            if (authData.isLoggedIn) {
                const prodId = authData.user.prodId;
                const deptId = authData.user.deptId;
                
                if (prodId !== null && deptId !== null) {
                    const [prodResponse, deptResponse] = await Promise.all([
                        fetch(`https://api.setventory.zoty.us/productions/select?id=${prodId}`),
                        fetch(`https://api.setventory.zoty.us/departments/select?id=${deptId}`)
                    ]);
    
                    const [prodData, deptData] = await Promise.all([prodResponse.json(), deptResponse.json()]);
    
                    if (prodData.status === 1 && prodData.data.length > 0) {
                        updateAuthData({
                            production: {
                                name: prodData.data[0].prod_name,
                                id: prodData.data[0].prod_id
                            }
                        })
                    }
    
                    if (deptData.status === 1 && deptData.data.length > 0) {
                        updateAuthData({
                            department: {
                                name: deptData.data[0].dept_name,
                                id: deptData.data[0].dept_id
                            }
                        })
                    }   
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <p className="loading"><i className="fa-regular fa-spinner fa-2xl fa-spin-pulse"></i></p>;
    }


    return (
        <>
            <h1>{authData.production ? authData.production.name : "Welcome to Setventory!"}</h1>
            <p>{authData.department ? authData.department.name : "Reach out to your production manager to receive access to your production."}</p>
        </>
    );
}

export default Dashboard;