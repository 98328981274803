import React from 'react';
import { useNavigate } from "react-router-dom";
import './MiniButtons.css';

const MiniButtons = ({
	btn1Content = null,
	btn1Callback = null,
	btn2Content = null,
	btn2Callback = null,
	alignment = "right"
}) => {
	const navigate = useNavigate();
	
	const handleBtn1Click = () => {
		if (typeof callback === 'function') {
			btn1Callback();
		} else {
			const parts = btn1Callback.toLowerCase().split(":");
			if (parts[0] === "navigate") {
				navigate("/" + parts[1]);
			}
		}
	}
	
	const handleBtn2Click = () => {
		if (typeof btn2Callback === 'function') {
			btn2Callback();
		}
	}
	
	const renderBtn1 = () => {
		if (btn1Content !== null) {
			return (<div className="btn" onClick={handleBtn1Click}>{btn1Content}</div>)
		}
	}
	
	const renderBtn2 = () => {
		if (btn2Content !== null) {
			return (<div className="btn secondary" onClick={handleBtn2Click}>{btn2Content}</div>)
		}
	}
	
	return (
		<div className={"minibuttons-container " + alignment}>
			{renderBtn2()}
			{renderBtn1()}
		</div>
	)
}

export default MiniButtons;