export const leftNavbarLinks = [
    {
        title: <i className="fa-regular fa-house"></i>,
        source: "dashboard",
        requiresAuth: false,
        notAuth: false
    },
    {
        title: <i className='fa-regular fa-square-dashed'></i>,
        source: "schemas",
        requiresAuth: false,
        notAuth: false
    },
    {
        title: <i className='fa-regular fa-plus'></i>, 
        source: "create-allocation",
        requiresAuth: false,
        notAuth: false
    },
    {
        title: <i className='fa-regular fa-table-list'></i>, 
        source: "inventories",
        requiresAuth: false,
        notAuth: false
    },
    {
        title: <i className='fa-regular fa-user'></i>,
        source: "account",
        requiresAuth: false,
        notAuth: false
    },
];

export const rightNavbarLinks = [
    {
        title: "Home",
        source: "",
        requiresAuth: false,
        notAuth: false
    },
    {
        title: "Sign Up",
        source: "signup",
        requiresAuth: false,
        notAuth: false
    },
    {
        title: "Log In",
        source: "login",
        requiresAuth: false,
        notAuth: false
    }
];