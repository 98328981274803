import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import InputPairs from '../components/InputGroups/InputGroups';
import TitleGroup from '../components/TitleGroup/TitleGroup';
import { apiPost } from "../../API";
import FancyButton from "../components/FancyButton/FancyButton";

function CreateInventory() {
    const navigate = useNavigate();
    const { authData } = useContext(AuthContext);

    const [missingItems, setMissingItems] = useState([]);
    const [formData, setFormData] = useState({
        prod_id: authData.user.prodId,
        dept_id: authData.user.deptId,
        inventory_name: '',
        inventory_desc: '',
        attributes: []
    });

    useEffect(() => {
        document.title = "Create Inventory";
    }, []);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleAttributesChange = (data) => {
        setFormData({
            ...formData,
            attributes: data.map(field => ({ 
                id: field.id,
                name: field.name, 
                datatype: field.datatype 
            }))
        });
    };

    const handleSubmit = async () => {
        const [res, err] = await apiPost('https://api.setventory.zoty.us/inventories/create', formData)
        
        console.log(res);
        if (!err) {
            if (res.status === 1) {
                navigate('/inventories');
            }
            if (res.missing) {
                setMissingItems(res.missing);
            }
        }
    }

    return (
        <>
            <TitleGroup title="Create Inventory" icon="fa-regular fa-table-list" />
            <p>This is like place to store all of your records. 
                You can set table-wide Attributes on the Inventory to apply them to every item within the table. You can assign Per-Item Attributes in Schemas.</p>
            <br />
            <form>
                <div>
                    <label htmlFor="inventory_name" hidden>Inventory Name</label>
                    <input
                        type="text"
                        name="inventory_name"
                        value={formData.inventory_name}
                        onChange={handleChange}
                        style={{ borderColor: missingItems.includes('inventory_name') ? 'var(--red)' : '' }}
                        placeholder="Inventory Name"
                    />
                    <label htmlFor="inventory_desc" hidden>Inventory Description</label>
                    <textarea
                        type="text"
                        name="inventory_desc"
                        value={formData.inventory_desc}
                        onChange={handleChange}
                        style={{ borderColor: missingItems.includes('inventory_desc') ? 'var(--red)' : '' }}
                        placeholder="Inventory Description"
                        rows="2"
                        autoComplete="off"
                    />
                </div>
                <input
                    type="hidden"
                    name="prod_id"
                    value={formData.prod_id}
                    onChange={handleChange}
                />
                <input
                    type="hidden"
                    name="dept_id"
                    value={formData.dept_id}
                    onChange={handleChange}
                />
                <InputPairs onFormDataChange={handleAttributesChange} />
            </form>
            <div className="hr-group">
                <hr hidden />
            </div>
            <FancyButton
                mainText={"Create Inventory"}
                mainIcon={<i className="fa-solid fa-plus"></i>}
                callback={handleSubmit}
                isSticky={true}
                otherContent={<i className="fa-solid fa-left"></i>}
                otherCallback={"Navigate:Inventories"}
            />
        </>
    );
}

export default CreateInventory;