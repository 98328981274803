import React, { createContext, useEffect, useState } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const initialAuthData = {
        isLoggedIn: false,
        user: {
            id: null,
            name: null,
            email: null,
            prodId: null,
            deptId: null
        },
        production: null,
        department: null
    };

    const [authData, setAuthData] = useState(() => {
        const storedAuthData = localStorage.getItem('authData');
        return storedAuthData ? JSON.parse(storedAuthData) : initialAuthData;
    });

    useEffect(() => {
        localStorage.setItem('authData', JSON.stringify(authData));
    }, [authData]);

    const updateAuthData = (input) => {
        setAuthData(prevAuthData => ({
            ...prevAuthData,
            ...input
        }));
    };

    const logout = () => {
        localStorage.removeItem('authData');
        setAuthData(initialAuthData);
    };

    return (
        <AuthContext.Provider value={{ 
            authData, 
            updateAuthData, 
            logout 
        }}>
            { children }
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
