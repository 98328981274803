import React, { useState, useEffect } from 'react';
import "./SelectDropdown.css";

function SelectDropdown({ options = [], onSelectChange, thing = "", selectedId }) {
    const [value, setValue] = useState(selectedId ? selectedId : "");

    useEffect(() => {
        if (selectedId !== undefined) {
            setValue(selectedId);
        }
    }, [selectedId]);

    const handleChange = (value) => {
        setValue(value);
        onSelectChange(value);
    }
    
    return (
        <div className="selectDropdown">
            <p>Select {thing}</p>
            <select
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                required
                >
                <option value="" disabled={selectedId === undefined}>Select {thing}</option>
                {options.map((option, index) => (
                    <option key={index} value={option.id}>{option.name}</option>
                    ))}
            </select>
        </div>
        )
}

export default SelectDropdown;
