import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import './Account.css';
function Account() {
    const navigate = useNavigate();
    const { authData, logout } = useContext(AuthContext);

    useEffect(() => {
        document.title = "Account";
    }, []);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <div className="text-center">
            <div className="bigIcon">
                <i className="fa-light fa-user"></i>
            </div>
            <div className="account-info-group">
                <p>{authData.production && authData.production.name}</p>
                <h1>{authData.user.name}</h1>
                <p>{authData.department ? authData.department.name : "Not in a Production"}</p>
            </div>
            <br />
            <div>
                <button onClick={handleLogout}>Log Out</button>
            </div>
        </div>
    );
}

export default Account;