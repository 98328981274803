export const apiPost = async (url, input) => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(input)
        });
        const data = await response.json();
        return [data, null];
    } catch (error) {
        console.error(error);
        return [null, error];
    }
};

export const apiMediaPost = async (url, input) => {
    try {
        let form_data = new FormData();
        form_data.append('prod_id', input.prod_id);
        form_data.append('dept_id', input.dept_id);
        form_data.append('blueprint_id', input.blueprint_id);
        form_data.append('inventory_id', input.inventory_id);
        input.allocation_data.map((item, key) => {
            form_data.append(`allocation[${key}][name]`, item.name)
            form_data.append(`allocation[${key}][data]`, item.data)
            form_data.append(`allocation[${key}][datatype]`, item.datatype)
            form_data.append(`allocation[${key}][id]`, item.id)
            return null;
        });

        const response = await fetch(url, {
            method: 'POST',
            body: form_data
        });
        const data = await response.json();
        return [data, null];
    } catch (error) {
        console.error(error);
        return [null, error];
    }
};

export const apiRequest = async (url) => {
    try {
        const response = await fetch(url);
        const data = await response.json();
        return [data, null];
    } catch (error) {
        console.error(error);
        return [null, error];
    }
}