import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../AuthContext';
import Card from '../components/Card/Card';
import { apiRequest } from "../../API";
import LoadingPlaceholder from '../components/LoadingPlaceholder/LoadingPlaceholder';

function Inventories() {
    const { authData } = useContext(AuthContext);

    const [inventories, setInventories] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Inventories";

        if (inventories == null) {
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        const deptId = authData.user.deptId;

        if (deptId !== null) {
            const [res, err] = await apiRequest(`https://api.setventory.zoty.us/inventories/attributes/select?department_id=${deptId}`);

            if (!err) {
                if (res.status === 1) {
                    console.log(res);
                    setInventories(res.data);
                }
            }
        }
        setLoading(false);
    }

    if (loading) {
        return <LoadingPlaceholder rows={3} />
    }
    
    return (
        <>
            <h1>Inventories</h1>
            {inventories && inventories.map((inventory, key) => {
                return <Card title={inventory.inventory_name} href={'view-inventory/' + inventory.inventory_id} description={inventory.inventory_desc} attributes={inventory.attributes} icon="fa-regular fa-table-list" key={key} />
            })}
            {inventories && <Card title='Create Inventory' href='create-inventory' attributes='' icon="fa-regular fa-plus" />}
            {!inventories && <p>You are not in a Production so you can't create these yet.</p>}
        </>
    );
}

export default Inventories;