import React from "react";
import './TitleGroup.css';

function TitleGroup({ title, icon, heading=1 }) {
    const generateHeading = (heading, children) => {
        if (heading === 1) {
            return <h1>{children}</h1>
        } else if (heading === 2) {
            return <h2>{children}</h2>
        }
    }
    return (
        <div className="title-group">
            {generateHeading(heading, title)}
            <i className={ icon }></i>
        </div>
    )
}

export default TitleGroup;